import * as yup from 'yup';

const infixArray = [ '', 't', 'a', 'aan', 'aan \'t', 'aan de', 'aan den', 'aan der', 'aan het', 'aan t', 'af', 'en', 'al', 'am', 'am de', 'auf', 'auf dem', 'auf der', 'auf ter', 'aus m', 'bij', 'bij de', 'bij den', 'bij het', 'bij t', 'bin', 'boven d', 'd', 'd.', 'd\'', 'da', 'dal', 'dalla', 'das', 'de', 'de die', 'de l', 'de l\'', 'de la', 'de las', 'de le', 'de van der', 'deca', 'degli', 'dei', 'del', 'della', 'den', 'der', 'des', 'di', 'do', 'don', 'dos', 'du', 'el', 'het', 'i', 'in', 'in \'t', 'in de', 'in den', 'in der', 'in het', 'in t', 'l', 'l\'', 'la', 'las', 'le', 'les', 'lo', 'los', 'of', 'onder', 'onder \'t', 'onder t', 'onder de', 'onder den', 'onder het', 'op', 'op \'t', 'op de', 'op den', 'op der', 'op gen', 'op het', 'op t', 'op ten', 'over', 'over de', 'over den', 'over het', 'over t', 's', 't', 'te', 'ten', 'ter', 'tho', 'thoe', 'thor', 'to', 'toe', 'tot', 'uijt', 'uijt de', 'uijt den', 'uijt te de', 'uijt ten', 'uit', 'uit de', 'uit den', 'uit het', 'uit t', 'uit te', 'uit te de', 'uit ten', 'unter', 'v.', 'v.d.', 'van', 'van \'t', 'van t', 'van de', 'van den', 'van der', 'van een', 'van gen', 'van het', 'van ter', 'vd', 'v d', 'ver', 'vom', 'von', 'von dem', 'von der', 'voor', 'voor de', 'voor den', 'vor', 'vor der', 'zu', 'zum', 'zur', 'op te', 'e/v' ];

let minAge = new Date();
minAge.setYear( minAge.getFullYear() - 18 );
minAge.setHours( 0 );
minAge.setMinutes( 0 );
minAge.setSeconds( 0 );

let maxAge = new Date();
maxAge.setYear( maxAge.getFullYear() - 120 );
maxAge.setHours( 0 );
maxAge.setMinutes( 0 );
maxAge.setSeconds( 0 );

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                gender: [
                    {
                        id: 'gender-a',
                        label: 'De heer',
                        type: 'radio',
                        // value: 'male',
                        name: 'gender',
                        tabindex: 1
                    },
                    {
                        id: 'gender-b',
                        label: 'Mevrouw',
                        type: 'radio',
                        // value: 'female',
                        name: 'gender',
                        tabindex: 2
                    }
                ],
                firstname: {
                    // name: 'firstname',
                    label: 'Voornaam',
                    placeholder: 'bv. jan',
                    name: 'firstname',
                    autocomplete: 'given-name',
                    tabindex: 3
                },

                infixname: {
                    label: 'Tussenv.',
                    placeholder: 'bv. van',
                    name: 'infixname',
                    autocomplete: 'infix-name',
                    tabindex: 4
                },

                surname: {
                    name: 'surname',
                    label: 'Achternaam',
                    placeholder: 'bv. jansen',
                    autocomplete: 'family-name',
                    tabindex: 5
                },

                dateOfBirth: {
                    label: 'Geboortedatum',
                    name: 'dateOfBirth',
                    type: 'date',
                    placeholder: 'dd-mm-yyyyy',
                    autocomplete: 'bday',
                    min: '1918-06-14',
                    max: '2003-06-14',
                    tabindex: 6
                }
            },

            validationSchema: yup.object().shape({
                gender: yup.string()
                    .label( 'aanhef' )
                    .required(),
                firstname: yup.string()
                    .label( 'voornaam' )
                    .required()
                    /* eslint-disable-next-line */
                    .matches( /^([A-z\-\s])+$/ )
                    .min( 2 ),
                infixname: yup.string()
                    .label( 'tussenvoegsel' )
                    .oneOf( infixArray, 'Dit is geen geldig tussenvoegsel' ),
                surname: yup.string()
                    .label( 'achternaam' )
                    .required()
                    /* eslint-disable-next-line */
                    .matches( /^([A-z\-\s])+$/ )
                    .min( 2 ),
                dateOfBirth: yup.date( 'Geen geldige datum' )
                    .label( 'geboortedatum' )
                    .required()
                    .max( '2003-06-14', 'Je moet minimaal 18 jaar zijn.' )
                    .min( '1914-06-14', 'Dit is geen geldige datum.' )
            })
        };
    }
};
