<template>
    <div class="naw-step3">
        <form @submit.prevent="onSubmit">
            <div class="row">
                <div class="col-12">
                    <label for="iban">{{ formConfig.iban.label }}</label>
                    <input :class="{ error: errors.iban }" v-bind="formConfig.iban" v-model="entry.iban" @blur="checkIfIbanExists" style="text-transform: uppercase;"/>
                    <span class="error" v-if="errors.iban">{{ errors.iban[0] }}</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <label for="telephone">{{ formConfig.telephone.label }}</label>
                    <input :class="{ error: errors.telephone }" v-bind="formConfig.telephone" v-model="entry.telephone" />
                    <span class="error" v-if="errors.telephone">{{ errors.telephone[0] }}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <label for="emailaddress">{{ formConfig.emailaddress.label }}</label>
                    <input :class="{ error: errors.emailaddress }" v-bind="formConfig.emailaddress" v-model="entry.emailaddress" @blur="checkMailDomain" />
                    <span class="error" v-if="errors.emailaddress">{{ errors.emailaddress[0] }}</span>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <input v-bind="formConfig.kit" v-model="entry.kit" style="position:absolute;" />
                    <label :for="formConfig.kit.id">
                        <div class="indicator"></div>
                        Ja, ik ontvang graag per e-mail interessante acties en aanbiedingen van Staatsloterij. <a href="https://www.nederlandseloterij.nl/emailvoorkeuren/staatsloterij" target="_blank">Lees meer</a>
                    </label>
                </div>
            </div>
            <div v-if="busy">
                <v-spinner stroke="#0000FF"></v-spinner>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { nawConfig3, fingerprintjs2, recaptcha } from '@/app/mixins';
    import Spinner from '@/app/components/Spinner.vue';

    export default {
        name: 'Naw3',

        components: {
            'v-spinner': Spinner
        },

        mixins: [ nawConfig3, fingerprintjs2, recaptcha ],

        data()
        {
            return {
                busy: false,
                entry: {
                    kit: false
                },

                validatedOnce: false,
                ibanExists: null,

                mailDomainError: null,

                errors: {
                }
            };
        },

        computed: {
            savedEntry()
            {
                if( this.$store.getters[ 'entry/all' ] )
                {
                    return this.$store.getters[ 'entry/all' ];
                }

                return false;
            }
        },

        watch: {
            'entry.telephone': function( pVal )
            {
                pVal = pVal.replace( /[-\s]/g, '' );

                pVal = pVal.replace( /^(\+31)/g, '0' );
                pVal = pVal.replace( /^(0031)/g, '0' );

                this.entry.telephone = pVal;
            },

            'entry.iban': function( pVal )
            {
                pVal = pVal.replace( /[-\s]/g, '' );

                this.entry.iban = pVal;
            },

            entry: {
                deep: true,
                handler( pVal )
                {
                    if( this.validatedOnce )
                    {
                        this.validate();
                    }
                }
            }
        },

        beforeDestroy()
        {
            // save if route away
            this.$store.dispatch( 'entry/merge', this.entry );
        },

        mounted()
        {
            // load entry if there is any
            if( this.savedEntry )
            {
                this.entry = Object.assign( this.entry, this.savedEntry );
            }
        },

        methods: {
            checkIfIbanExists()
            {
                // if( this.ibanExists === true )
                // {
                //     return;
                // }
                return new Promise( resolve =>
                {
                    return this.$store.dispatch( 'entry/checkIban', { iban: this.entry.iban }).then( result =>
                    {
                        if( !result.exists )
                        {
                            this.ibanExists = false;
                            this.errors[ 'iban' ] = [];
                        }
                        else
                        {
                            this.ibanExists = true;
                            this.errors[ 'iban' ] = [ 'Dit Iban nummer doet al mee met de actie' ];

                            // // scroll to
                            // this.scrollToError();
                        }

                        resolve();
                        this.$forceUpdate();
                    });
                });
            },
            checkMailDomain()
            {
                return new Promise( resolve =>
                {
                    return this.$store.dispatch( 'entry/checkMailDomain', { emailaddress: this.entry.emailaddress }).then( result =>
                    {
                        if( result.success === true )
                        {
                            this.mailDomainError = false;
                        }
                        else
                        {
                            this.mailDomainError = true;

                            // this.errors[ 'emailaddress' ] = [ result.errors.emailaddress[ 0 ] ];
                            this.errors[ 'emailaddress' ] = [ 'Dit maildomein is ongeldig.' ];

                            this.$forceUpdate();

                            // // scroll to
                            // this.scrollToError();
                        }

                        resolve();
                    });
                });
            },

            async validate()
            {
                this.validatedOnce = true;

                return this.validationSchema.validate( this.entry, { abortEarly: false })
                    .then( isValid =>
                    {
                        this.errors = {};

                        if( this.ibanExists === true )
                        {
                            this.errors[ 'iban' ] = [ 'Dit Iban nummer doet al mee met de actie.' ];
                        }
                        if( this.mailDomainError === true )
                        {
                            this.errors[ 'emailaddress' ] = [ 'Dit is geen geldig maildomein.' ];
                        }
                    })
                    .catch( error =>
                    {
                        for( let item in this.entry )
                        {
                            if( !this.errors[ item ] )
                            {
                                this.errors[ item ] = [];
                            }
                        }

                        for( let err of error.inner )
                        {
                            if( !this.errors[ err.path ] )
                            {
                                this.errors[ err.path ] = [];
                            }

                            this.errors[ err.path ].push( err.errors[ 0 ] );
                        }

                        for( let item in this.errors )
                        {
                            if( this.errors[item].length === 0 )
                            {
                                delete this.errors[item];
                            }
                        }

                        this.$forceUpdate();

                        // this.scrollToError();
                    });
            },

            async onSubmit()
            {
                if( this.waitingForApi )
                {
                    return;
                }

                this.errors = {};
                this.apiError = false;
                this.busy = true;

                await this.checkIfIbanExists();
                await this.validate();
                await this.checkMailDomain();

                if( Object.keys( this.errors ).length === 0 )
                {
                    this.$store.dispatch( 'entry/merge', this.entry ).then( () =>
                    {
                        this.$emit( 'formContinue' );
                    });
                }
                else
                {
                    this.busy = false;
                }
            }
        }
    };
</script>
