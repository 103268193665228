<template>
    <div class="naw-step0">
        <hr />
        <form @submit.prevent="onSubmit">
            <div class="row text-left">
                <div class="col-6">
                    <img src="/static/img/ticket.png" alt="" class="visual">
                </div>
                <div class="col-6">
                    <h3 class="blue">Staatslot</h3>
                    <p>Kans op de Jackpot en alle andere geldprijzen + exclusieve abonnee voordelen.</p>
                    <number-stepper v-model="ticketsWanted" :min="1" :max="5"></number-stepper>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>Je geluksgetal en je eindcijfers:</span>
                    <span v-for="( ticket, i ) in entry.tickets" @click.prevent="change(i)" class="ticket-number-pill">
                        <span>{{ ticket.split('')[0] }}</span>
                        <span>{{ ticket.split('')[1] }}</span>
                    </span>
                </div>
            </div>

            <div class="row">
                <dl :class="{ open: abboInfo }">
                    <dt @click="abboInfo = !abboInfo">Wat houdt mijn abonnement in?</dt>
                    <dd>
                        <p>Het abonnement bestaat uit:</p>
                        <ul>
                            <li>12 trekkingen met een lot van € 15,- of € 17,50 met optie XL</li>
                            <li>3 event trekkingen: Koningsdag, 1 juli en 1 oktober trekking met een lot van € 15,-</li>
                            <li>de oudejaarstrekking met een lot van € 30,-</li>
                        </ul>
                    </dd>
                </dl>
            </div>

            <div class="row">
                <input v-bind="formConfig.xl" v-model="xl" style="position:absolute;" />
                <label :for="formConfig.xl.id" class="xl">
                    <div class="indicator"></div>
                    Ik activeer ook XL voor €2,50 per trekking en maak daarmee kans op extra prijzen.
                </label>
            </div>

            <div class="row">
                <dl :class="{ open: xlInfo }">
                    <dt @click="xlInfo = !xlInfo">Wat is XL?</dt>
                    <dd>
                        <p>Door mee te spelen met XL maak je iedere 10e van de maand ook met je begincijfers kans op prijzen van €100 tot en met €1.000.000. Dat is dus twee keer kans met hetzelfde lotnummer. De XL-prijzen worden door middel van een extra trekking getrokken uit alle verkochte lotnummers van die maand. Je betaalt voor XL €2,50 extra op basis van een heel lot.</p>
                    </dd>
                </dl>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
    @import 'base/colors';
    form .row:first-of-type{
        margin-bottom:1rem;
    }
    .visual {
        width: 130%;
        max-width: none;
        position: relative;
        left: -30%;
    }
    .xl {
        border: 1px solid $light-blue;
        background-color: rgba( $light-blue, 0.25 );
        color: $blue;
        padding: 1em;
        border-radius: 5px;
        transition: background-color 0.3s ease-out;
        position: relative;
        .indicator {
            position: relative;
        }
    }
    input[name="xl"]:checked + label {
        background-color: rgba( $light-blue, 1 );
        color: white;
    }
</style>

<script>
    import { nawConfig0 } from '@/app/mixins';
    import { EventBus } from '@/app/event-bus';
    import NumberPicker from '@/app/components/NumberPicker.vue';
    import NumberStepper from '@/app/components/NumberStepper.vue';

    export default {
        name: 'Naw0',

        components: {
            'number-stepper': NumberStepper
        },

        mixins: [ nawConfig0 ],

        data()
        {
            return {
                abboInfo: false,
                xlInfo: false,
                validatedOnce: false,
                ticketsWanted: 1,

                errors: {

                }
            };
        },

        computed: {

            entry()
            {
                return this.$store.getters[ 'entry/all' ];
            },
            tickets: {
                get()
                {
                    return [...this.entry.tickets];
                },
                set( val )
                {
                    this.$store.dispatch( 'entry/merge', { tickets: val });
                }
            },
            xl: {
                get()
                {
                    return this.entry.tickets.xl;
                },
                set( val )
                {
                    this.$store.dispatch( 'entry/merge', { xl: val });
                }
            }
        },

        watch: {
            entry: {
                deep: true,
                handler( pVal )
                {
                    if( this.validatedOnce )
                    {
                        this.validate();
                    }
                }
            },
            ticketsWanted( val )
            {
                let t = this.tickets.slice( 0, val );
                if( val > this.tickets.length )
                {
                    t.push( '??' );
                }
                this.tickets = t;
            }
        },

        beforeDestroy()
        {
            // save if route away
            this.$store.dispatch( 'entry/merge', this.entry );
        },

        mounted()
        {
            // load entry if there is any
            if( this.savedEntry )
            {
                this.entry = Object.assign( this.entry, this.savedEntry );
            }
        },

        methods: {
            async change( i )
            {
                let c = {
                    component: NumberPicker,
                    model: this.entry.tickets[ i ],
                    closed: () =>
                    {
                        let tickets = [...this.entry.tickets];
                        tickets[ i ] = c.model;
                        console.log( tickets );
                        this.$store.dispatch( 'entry/merge', { tickets });
                        console.log( 'closed', i, c.model );
                    }
                };
                EventBus.$emit( 'overlay', c );
            },
            async validate()
            {
                return new Promise( resolve =>
                {
                    this.validatedOnce = true;

                    return this.validationSchema.validate( this.entry, { abortEarly: false })
                        .then( isValid =>
                        {
                            this.errors = {};
                            resolve();
                        })
                        .catch( error =>
                        {
                            for( let item in this.entry )
                            {
                                this.errors[ item ] = [];
                            }

                            for( let err of error.inner )
                            {
                                if( !this.errors[ err.path ] )
                                {
                                    this.errors[ err.path ] = [];
                                }

                                this.errors[ err.path ].push( err.errors[ 0 ] );
                            }

                            for( let item in this.errors )
                            {
                                if( this.errors[item].length === 0 )
                                {
                                    delete this.errors[item];
                                }
                            }

                            this.$forceUpdate();

                            // this.scrollToError();
                        });
                });
            },

            async onSubmit()
            {
                await this.validate();

                if( Object.keys( this.errors ).length === 0 )
                {
                    this.$store.dispatch( 'entry/merge', this.entry ).then( () =>
                    {
                        this.$emit( 'formContinue' );
                    });
                }
            }
        }
    };
</script>
