<template>
    <div class="naw-step-abo">
        <form @submit.prevent="onSubmit">
            <p>Bevestig je deelname en je speelt vanaf <strong v-text="startDate">1 juli 2021</strong> automatisch mee met Staatsloterij. Je speelt mee zo lang je wil.</p>

            <div class="row text-left">
                <div class="col12">
                    <h4 class="blue">Abonnement Staatslot {{ entry.xl ? 'met XL' : '' }}</h4>
                </div>
                <div class="col-12 m-col-4">
                    <img src="/static/img/ticket.png" alt="" class="visual">
                </div>
                <div class="col-12 m-col-8 text-right">
                    <strong>{{ totalCosts }}</strong><br />
                    ({{ tickets.length }} x {{ singleCosts }})
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <span>Jouw eindcijfers:</span>
                    <span v-for="ticket in entry.tickets" class="ticket-number-pill readonly">
                        <span>{{ ticket.split('')[0] }}</span>
                        <span>{{ ticket.split('')[1] }}</span>
                    </span>
                </div>
            </div>
            <div class="row text-left">
                <div class="col-3 m-col-12">&nbsp;</div>
                <div class="col-6 m-col-3">
                    <img src="/static/img/waaier.png" alt="">
                </div>
                <div class="col-12 m-col-6">
                    <h4 class="orange with-pop">+ 5 gratis 1/5&nbsp;loten<img src="/static/img/info.png" /><span class="popover">Bij de eerste vijf trekkingen van dit abonnement, waarbij de Ouderjaarstrekking is uitgesloten, ontvang je voor elke succesvolle incasso een gratis extra 1/5 Staatslot ter waarde van €3,00 (of €3,50 als je kiest voor speloptie XL). Indien de incasso niet is geslaagd, komt het gratis 1/5 Staatslot voor deze en de eventueel opvolgende trekkingen te vervallen.</span><br />
                        + kan op een extra prijs</h4>
                </div>
            </div>

            <dl :class="{ open: abboInfo }">
                <dt @click="abboInfo = !abboInfo">Wat houdt mijn abonnement in?</dt>
                <dd>
                    <p>Het abonnement bestaat uit:</p>
                    <ul>
                        <li>12 trekkingen met een lot van € 15,- of € 17,50 met optie XL</li>
                        <li>3 event trekkingen: Koningsdag, 1 juli en 1 oktober trekking met een lot van € 15,-</li>
                        <li>de oudejaarstrekking met een lot van € 30,-</li>
                    </ul>
                </dd>
            </dl>

            <div class="row">
                <div class="col-12">
                    <input v-bind="formConfig.av" v-model="av" style="position:absolute;" />
                    <label :for="formConfig.av.id">
                        <div class="indicator"></div>
                        Ik verklaar dat ik dit formulier naar waarheid heb ingevuld. Ik ga akkoord met de <a href="/static/pdf/actievoorwaarden.pdf" target="_blank">actievoorwaarden</a>, <a href="https://www.nederlandseloterij.nl/deelnemersreglementen" target="_blank">deelnemersreglement</a> en het <a href="https://www.nederlandseloterij.nl/privacy" target="_blank">privacybeleid</a>.
                    </label>
                    <span class="error" v-if="errors.av">{{ errors.av[0] }}</span>
                </div>
            </div>
            <div v-if="waitingForApi">
                <v-spinner stroke="#0000FF"></v-spinner>
            </div>
            <p v-if="apiError">
                <span class="error">Er is iets mis gegaan. Probeer het later nog eens.</span>
            </p>
        </form>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { nawConfig4, fingerprintjs2, recaptcha } from '@/app/mixins';
    import Spinner from '@/app/components/Spinner.vue';

    let formatter = new Intl.NumberFormat( 'nl-NL', {
        style: 'currency',
        currency: 'EUR'
        // These options are needed to round to whole numbers if that's what you want.
        // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    export default {
        name: 'NawAbo',

        components: {
            'v-spinner': Spinner
        },

        mixins: [ nawConfig4, fingerprintjs2, recaptcha ],

        data()
        {
            return {
                apiError: false,
                waitingForApi: false,
                abboInfo: false,
                validatedOnce: false,

                mailDomainError: null,

                errors: {
                }
            };
        },

        computed: {
            startDate()
            {
                let now = new Date();
                if( now < new Date( '2021-06-08 23:00:00' ) )
                {
                    return '1 juli 2021';
                }
                if( now < new Date( '2021-06-15 23:00:00' ) )
                {
                    return '10 juli 2021';
                }
                if( now < new Date( '2021-06-30 23:00:00' ) )
                {
                    return '10 augustus 2021';
                }
                return '10 september 2021';
            },
            costs()
            {
                return ( 15 + ( this.entry.xl ? 2.5 : 0 ) );
            },
            singleCosts()
            {
                return formatter.format( this.costs );
            },
            totalCosts()
            {
                return formatter.format( this.costs * this.tickets.length );
            },
            entry()
            {
                return this.$store.getters[ 'entry/all' ];
            },
            tickets: {
                get()
                {
                    return [...this.entry.tickets];
                },
                set( val )
                {
                    this.$store.dispatch( 'entry/merge', { tickets: val });
                }
            },
            av: {
                get()
                {
                    return this.entry.av;
                },
                set( val )
                {
                    this.$store.dispatch( 'entry/merge', { av: val });
                }
            }
        },

        watch: {
            entry: {
                deep: true,
                handler( pVal )
                {
                    if( this.validatedOnce )
                    {
                        this.validate();
                    }
                }
            }
        },

        beforeDestroy()
        {
            // save if route away
            this.$store.dispatch( 'entry/merge', this.entry );
        },

        mounted()
        {
            // load entry if there is any
            if( this.savedEntry )
            {
                this.entry = Object.assign( this.entry, this.savedEntry );
            }
        },

        methods: {

            async validate()
            {
                this.validatedOnce = true;

                return this.validationSchema.validate( this.entry, { abortEarly: false })
                    .then( isValid =>
                    {
                        this.errors = {};
                    })
                    .catch( error =>
                    {
                        for( let item in this.entry )
                        {
                            if( !this.errors[ item ] )
                            {
                                this.errors[ item ] = [];
                            }
                        }

                        for( let err of error.inner )
                        {
                            if( !this.errors[ err.path ] )
                            {
                                this.errors[ err.path ] = [];
                            }

                            this.errors[ err.path ].push( err.errors[ 0 ] );
                        }

                        for( let item in this.errors )
                        {
                            if( this.errors[item].length === 0 )
                            {
                                delete this.errors[item];
                            }
                        }

                        this.$forceUpdate();

                        // this.scrollToError();
                    });
            },

            async onSubmit()
            {
                if( this.waitingForApi )
                {
                    return;
                }

                this.errors = {};
                this.apiError = false;

                await this.validate();

                if( Object.keys( this.errors ).length === 0 )
                {
                    this.waitingForApi = true;
                    if( window.location.href.indexOf( '192.168' ) === -1 )
                    {
                        this.entry['g-recaptcha-response'] = await this.executeRecaptcha();
                    }

                    this.entry['origin'] = window._rootData.r;
                    console.log( this.entry.dateOfBirth );
                    this.entry['date_of_birth'] = this.entry.dateOfBirth;

                    this.$store.dispatch( 'entry/save', this.entry )
                        .then( result =>
                        {
                            this.waitingForApi = false;

                            this.$emit( 'formContinue' );
                        })
                        .catch( errors =>
                        {
                            console.log( 'api error' );
                            this.waitingForApi = false;
                            this.apiError = true;
                        });
                }
            }
        }
    };
</script>
