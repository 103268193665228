<template>
    <v-panel class="number-picker">
        <a slot="back" href="#" @click.prevent="close(false)">&lt; Annuleren</a>
        <p class="speech"><span>Speel mee en kies jouw gelukscijfer.<br />
            Bij één goed eindcijfer heb je al prijs!</span></p>
        <p>Kies je eindcijfers <span>(optioneel, max&nbsp;2)</span></p>
        <span class="ticket-number-pill">
            <span>{{ newValue.split('')[0] }}</span>
            <span>{{ newValue.split('')[1] }}</span>
        </span>
        <div class="row">
            <div class="col-1">&nbsp;</div>
            <div class="col-2" @click="add(1)"><span class="circ"><span>1</span></span></div>
            <div class="col-2" @click="add(2)"><span class="circ"><span>2</span></span></div>
            <div class="col-2" @click="add(3)"><span class="circ"><span>3</span></span></div>
            <div class="col-2" @click="add(4)"><span class="circ"><span>4</span></span></div>
            <div class="col-2" @click="add(5)"><span class="circ"><span>5</span></span></div>
            <div class="col-1">&nbsp;</div>
        </div>
        <div class="row">
            <div class="col-1">&nbsp;</div>
            <div class="col-2" @click="add(6)"><span class="circ"><span>6</span></span></div>
            <div class="col-2" @click="add(7)"><span class="circ"><span>7</span></span></div>
            <div class="col-2" @click="add(8)"><span class="circ"><span>8</span></span></div>
            <div class="col-2" @click="add(9)"><span class="circ"><span>9</span></span></div>
            <div class="col-2" @click="add(0)"><span class="circ"><span>0</span></span></div>
            <div class="col-1">&nbsp;</div>
        </div>
        <a href="#" @click.prevent="random" class="random">Kies random getal</a>
        <button slot="button" class="stl" @click="close(true)" :disabled="value.length < 2">Bevestigen</button>
    </v-panel>
</template>

<script>
    import gsap from 'gsap';
    import Panel from '@/app/components/Panel';
    import { EventBus } from '@/app/event-bus';
    export default {
        name: 'NumberPicker',
        components: {
            'v-panel': Panel
        },
        props: {
            value: {
                type: String,
                default: ''
            }
        },
        data()
        {
            return {
                newValue: ''
            };
        },
        created()
        {
            this.newValue = this.value;
            this.base = { a: Number( this.value ) };
        },
        methods: {
            add( num )
            {
                this.newValue += '' + num;
                this.newValue = this.newValue.substr( -2 );
                console.log( this.newValue );
            },
            close( confirmed )
            {
                if( confirmed )
                {
                    this.$emit( 'input', this.newValue );
                }
                EventBus.$emit( 'overlay:close' );
            },
            random()
            {
                let target = Math.floor( Math.random() * 100 );
                this.base.a = Number( this.number );
                gsap.to( this.base, {
                    a: target,
                    duration: 0.3,
                    roundProps: 'a',
                    onUpdate: () =>
                    {
                        this.newValue = ( '0' + this.base.a ).substr( -2 );
                    } });
            }
        }
    };

</script>

<style>

</style>
