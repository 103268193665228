<template>
    <div class="naw-step2">

        <form @submit.prevent="onSubmit">
            <div class="row">
                <label for="zipcode">{{ formConfig.zipcode.label }}</label>
                <input :class="{ error: errors.zipcode }" v-bind="formConfig.zipcode" @blur="checkAddress" v-model="entry.zipcode" />
                <span class="error" v-if="errors.zipcode">{{ errors.zipcode[0] }}</span>
            </div>
            <div class="row">
                <label for="house_num">{{ formConfig.house_num.label }}</label>
                <input :class="{ error: errors.house_num }" v-bind="formConfig.house_num" @blur="checkAddress" v-model="entry.house_num" />
                <span class="error" v-if="errors.house_num">{{ errors.house_num[0] }}</span>
            </div>

            <div class="row">
                <label for="house_num_add">{{ formConfig.house_num_add.label }}</label>
                <input :class="{ error: errors.house_num_add }" v-bind="formConfig.house_num_add" @blur="checkAddress" v-model="entry.house_num_add" />
                <span class="error" v-if="errors.house_num_add">{{ errors.house_num_add[0] }}</span>
            </div>

            <div class="row">
                <div class="col-12">
                    <span class="error text-center" v-if="addressError" style="width: 100%; display:inline-block;">Adres niet gevonden.</span>
                    <div class="address-preview" v-if="( entry.street && entry.city ) || waitingForAddress">
                        <v-spinner stroke="#000" v-if="waitingForAddress"></v-spinner>
                        <span v-if="entry.street && entry.city">{{ addressLine }}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import Vue from 'vue';
    import Spinner from '@/app/components/Spinner';
    import { nawConfig2 } from '@/app/mixins';

    export default {
        name: 'Naw2',

        components: {
            'v-spinner': Spinner
        },

        mixins: [ nawConfig2 ],

        data()
        {
            return {
                entry: {
                    zipcode: '',
                    house_num: null,
                    house_num_add: '',
                    street: null,
                    city: null
                },

                waitingForAddress: false,
                addressError: null,

                validatedOnce: false,

                errors: {

                }
            };
        },

        computed: {
            addressLine()
            {
                return this.entry.street + ' ' + this.entry.house_num + ' ' + this.entry.house_num_add + ', ' + this.entry.city;
            },
            savedEntry()
            {
                if( this.$store.getters[ 'entry/all' ] )
                {
                    return this.$store.getters[ 'entry/all' ];
                }

                return false;
            }
        },

        watch: {
            'entry.zipcode': function( pVal )
            {
                if( pVal )
                {
                    pVal = pVal.replace( / /g, '' );
                    pVal = pVal.toUpperCase();
                }

                this.entry.zipcode = pVal;
            },

            'entry.house_num': function( pVal )
            {
                if( pVal === '' )
                {
                    this.entry.house_num = null;
                }
            },

            entry: {
                deep: true,
                handler( pVal )
                {
                    if( this.validatedOnce )
                    {
                        this.validate();
                    }
                }
            }
        },

        beforeDestroy()
        {
            // save if route away
            this.$store.dispatch( 'entry/merge', this.entry );
        },

        mounted()
        {
            // load entry if there is any
            if( this.savedEntry )
            {
                this.entry = Object.assign( this.entry, this.savedEntry );
            }
        },

        methods: {
            checkAddress( e )
            {
                // this.stripSpaces( e );
                if( this.lastAddress === this.entry.zipcode + this.entry.house_num + this.entry.house_num_add )
                {
                    return;
                }
                if( !this.entry.house_num )
                {
                    return;
                }

                this.addressError = false;
                this.lastAddress = this.entry.zipcode + this.entry.house_num + this.entry.house_num_add;
                if( this.entry.zipcode.length > 5 && this.entry.house_num.length > 0 )
                {
                    let url = window._rootData.apiUrl + '/api/address';
                    this.waitingForAddress = true;
                    Vue.$http.post( url, { zipcode: this.entry.zipcode, house_num: this.entry.house_num }).then( ( result ) =>
                    {
                        if( result.data.errorCode )
                        {
                            throw new Error( result.data.message );
                        }
                        this.entry.street = result.data.street;
                        this.entry.city = result.data.city;
                        this.waitingForAddress = false;
                    }).catch( ( result ) =>
                    {
                        this.addressError = true;
                        this.entry.street = null;
                        this.entry.city = null;
                        this.waitingForAddress = false;
                    });
                }
            },

            async validate()
            {
                this.validatedOnce = true;

                return this.validationSchema.validate( this.entry, { abortEarly: false })
                    .then( isValid =>
                    {
                        this.errors = {};
                    })
                    .catch( error =>
                    {
                        for( let item in this.entry )
                        {
                            this.errors[ item ] = [];
                        }

                        for( let err of error.inner )
                        {
                            if( !this.errors[ err.path ] )
                            {
                                this.errors[ err.path ] = [];
                            }

                            this.errors[ err.path ].push( err.errors[ 0 ] );
                        }

                        for( let item in this.errors )
                        {
                            if( this.errors[item].length === 0 )
                            {
                                delete this.errors[item];
                            }
                        }

                        this.$forceUpdate();

                        // this.scrollToError();
                    });
            },

            async onSubmit()
            {
                if( this.waitingForAddress )
                {
                    return;
                }

                await this.checkAddress();
                await this.validate();

                if( this.addressError )
                {
                    return;
                }

                if( Object.keys( this.errors ).length === 0 )
                {
                    this.$store.dispatch( 'entry/merge', this.entry ).then( () =>
                    {
                        this.$emit( 'formContinue' );
                    });
                }
            }
        }
    };
</script>
