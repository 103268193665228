<template>
    <div class="naw-step1">
        <form @submit.prevent="onSubmit">
            <div class="row">
                <div class="col-6 m-col-4">
                    <input value="male" v-bind="formConfig.gender[0]" v-model="entry.gender" />
                    <label for="gender-a" v-if="formConfig.gender[0].label">
                        <div class="indicator"></div>
                        {{ formConfig.gender[0].label }}
                    </label>
                </div>
                <div class="col-6 m-col-6">
                    <input value="female" v-bind="formConfig.gender[1]" v-model="entry.gender" />
                    <label for="gender-b" v-if="formConfig.gender[1].label">
                        <div class="indicator"></div>
                        {{ formConfig.gender[1].label }}
                    </label>
                </div>
            </div>
            <div class="row">
                <span class="error" v-if="errors.gender">{{ errors.gender[0] }}</span>
            </div>

            <div class="row">
                <div class="col-8">
                    <label for="firstname">{{ formConfig.firstname.label }}</label>
                    <input :class="{ error: errors.firstname }" v-bind="formConfig.firstname" v-model="entry.firstname" />
                    <span class="error" v-if="errors.firstname">{{ errors.firstname[0] }}</span>
                </div>
                <div class="col-4">
                    <label for="infixname">{{ formConfig.infixname.label }}</label>
                    <input :class="{ error: errors.infixname }" v-bind="formConfig.infixname" v-model="entry.infixname" />
                    <span class="error" v-if="errors.infixname">{{ errors.infixname[0] }}</span>
                </div>
            </div>

            <div class="row">
                <label for="surname">{{ formConfig.surname.label }}</label>
                <input :class="{ error: errors.surname }" v-bind="formConfig.surname" v-model="entry.surname" />
                <span class="error" v-if="errors.surname">{{ errors.surname[0] }}</span>
            </div>

            <span class="label">Geboortedatum <span class="small">(vanaf 18 jaar)</span></span>

            <div class="row">
                <div class="col-12">
                    <!-- <label for="dayOfBirth">{{ formConfig.dayOfBirth.label }}</label> -->
                    <input :class="{ error: errors.dateOfBirth }" v-bind="formConfig.dateOfBirth" v-model="entry.dateOfBirth" type="date" />
                    <span class="error" v-if="errors.dateOfBirth">Je moet minimaal 18 jaar zijn.</span>
                </div>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
    form .row:first-of-type{
        margin-bottom:1rem;
    }
</style>

<script>
    import { nawConfig1 } from '@/app/mixins';

    export default {
        name: 'Naw1',

        mixins: [ nawConfig1 ],

        data()
        {
            return {
                entry: {
                    gender: '',
                    firstname: '',
                    infixname: '',
                    surname: '',
                    dateOfBirth: null
                },

                validatedOnce: false,

                errors: {

                }
            };
        },

        computed: {

            savedEntry()
            {
                if( this.$store.getters[ 'entry/all' ] )
                {
                    return this.$store.getters[ 'entry/all' ];
                }

                return false;
            }
        },

        watch: {
            entry: {
                deep: true,
                handler( pVal )
                {
                    if( this.validatedOnce )
                    {
                        this.validate();
                    }
                }
            }
        },

        beforeDestroy()
        {
            // save if route away
            this.$store.dispatch( 'entry/merge', this.entry );
        },

        mounted()
        {
            // load entry if there is any
            if( this.savedEntry )
            {
                this.entry = Object.assign( this.entry, this.savedEntry );
            }
        },

        methods: {
            async validate()
            {
                return new Promise( resolve =>
                {
                    this.validatedOnce = true;

                    return this.validationSchema.validate( this.entry, { abortEarly: false })
                        .then( isValid =>
                        {
                            this.errors = {};
                            resolve();
                        })
                        .catch( error =>
                        {
                            for( let item in this.entry )
                            {
                                this.errors[ item ] = [];
                            }

                            for( let err of error.inner )
                            {
                                if( !this.errors[ err.path ] )
                                {
                                    this.errors[ err.path ] = [];
                                }

                                this.errors[ err.path ].push( err.errors[ 0 ] );
                            }

                            for( let item in this.errors )
                            {
                                if( this.errors[item].length === 0 )
                                {
                                    delete this.errors[item];
                                }
                            }

                            this.$forceUpdate();

                            // this.scrollToError();
                        });
                });
            },

            async onSubmit()
            {
                await this.validate();

                if( Object.keys( this.errors ).length === 0 )
                {
                    this.$store.dispatch( 'entry/merge', this.entry ).then( () =>
                    {
                        this.$emit( 'formContinue' );
                    });
                }
            }
        }
    };
</script>
