import * as yup from 'yup';

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                xl: {
                    type: 'checkbox',
                    name: 'xl',
                    id: 'xl-checkbox',
                    tabindex: 0
                }
            },

            validationSchema: yup.object().shape({})
        };
    }
};
