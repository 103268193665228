import * as yup from 'yup';

export default {
    beforeCreate()
    {
        yup.setLocale({
            mixed: {
                required: obj => `${obj.label ? obj.label : obj.path} is verplicht` // `${obj.path} is default`
            },
            string: {
                min: obj => `${obj.path} moet minstens ${obj.min} karakters lang zijn`,
                matches: obj => `Dit is geen geldig ${obj.label}.`
            }
        });
    },

    data()
    {
        return {
            formConfig: {
                av: {
                    type: 'checkbox',
                    name: 'av',
                    id: 'av-checkbox',
                    tabindex: 15
                }
            },

            validationSchema: yup.object().shape({
                av: yup.bool()
                    .oneOf( [ true ], 'Je moet het formulier naar waarheid invullen.' )
            })
        };
    }
};
