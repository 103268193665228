<template>
    <div class="naw-page">
        <v-panel ref="panel">
            <a slot="back" href="#" @click.prevent="$router.back()">&lt; Terug</a>

            <div>
                <div v-if="$route.meta.step === 0">
                    <h1>Welke prijs krijg jij?</h1>
                    <p class="with-pop">Met hoeveel loten wil je meespelen? Je krijgt daarbij GRATIS 5x een 1/5 Staatslot + extra kans op prijzen.<img src="/static/img/info.png" /><span class="popover">Bij de eerste vijf trekkingen van dit abonnement,waarbij de ouderjaarstrekking is uitgesloten, ontvang je voor elke succesvolle incasso een gratis extra 1/5 Staatslot ter waarde van €3,00 (of €3,50 als je kiest voor speloptie XL). Indien de incasso niet is geslaagd, komt het gratis 1/5 Staatslot voor deze en de eventueel opvolgende trekkingen te vervallen.</span></p>
                </div>
                <div v-if="$route.meta.step === 1">
                    <h1>Scoor jij zo een extra prijs...</h1>
                    <p>Wat is je naam en wanneer ben je geboren?</p>
                </div>
                <div v-if="$route.meta.step === 2">
                    <h1>Welke prijs ligt er op {{ entry.tickets[0] }}&nbsp;meter?</h1>
                    <p>Hallo {{ entry.firstname }}, naar welk adres kunnen we de gewonnen prijs sturen?</p>
                </div>
                <div v-if="$route.meta.step === 3">
                    <h1>Nog 1 stap verwijderd van 5 gratis 1/5&nbsp;loten.</h1>
                    <p>Op welk rekeningnummer wil je de geldprijzen ontvangen {{ entry.firstname }}?</p>
                </div>
                <div v-if="$route.meta.step === 4">
                    <h1>De bal is onderweg!</h1>
                    <p>We zetten alles nog even op een rij.</p>
                </div>
            </div>

            <div class="indicator-wrapper" v-if="$route.meta.step">
                <div :class="{ 'dot': true, 'active': $route.meta.step > 1 }">1</div>
                <div class="line hide"></div>
                <div :class="{ 'dot': true, 'active': $route.meta.step > 2, 'hide': $route.meta.step < 2 }">2</div>
                <div class="line hide"></div>
                <div :class="{ 'dot': true, 'active': $route.meta.step > 3, 'hide': $route.meta.step < 3 }">3</div>
                <div class="line hide"></div>
                <div :class="{ 'dot': true, 'hide': $route.meta.step < 4 }">4</div>
            </div>
            <router-view ref="currentForm" @formContinue="onFormContinue"></router-view>

            <button class="stl" slot="button" @click="trySubmit">
                <span v-if="$route.meta.step === 0">Naar gegevens</span>
                <span v-if="$route.meta.step === 1">Naar adres</span>
                <span v-if="$route.meta.step === 2">Naar contact</span>
                <span v-if="$route.meta.step === 3">Naar besteloverzicht</span>
                <span v-if="$route.meta.step === 4">Bevestigen</span>
            </button>
        </v-panel>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    import Panel from '@/app/components/Panel';
    import { gsap } from 'gsap';

    export default {
        components: {
            'v-panel': Panel
        },

        computed: {
            score()
            {
                return this.$store.getters[ 'entry/all' ].score;
            },
            entry()
            {
                return this.$store.getters['entry/all'];
            }
        },

        mounted()
        {
        },

        methods: {

            getAnimateIn()
            {
                let tl = gsap.timeline();
                tl.add( () =>
                {
                    this.$store.dispatch( 'fieldState', 'clouds' );
                });
                tl.from( this.$refs.panel.$el, { translateX: 10, /* translateZ: -200, rotateY: '-90deg', */ autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0 );
                return tl;
            },

            getAnimateOut()
            {
                let tl = gsap.timeline();
                tl.to( this.$refs.panel.$el.children, { autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0 );
                tl.to( this.$refs.panel.$el, { translateX: 10, /* translateZ: -200, rotateY: '90deg', */ autoAlpha: 0, ease: 'sine.out', duration: 0.3 }, 0 );
                return tl;
            },

            onFormContinue()
            {
                if( this.entry.nyxid )
                {
                    this.$router.push( '/bedankt' );
                    return;
                }

                if( this.$route.meta.step === 4 )
                {
                    this.$router.push( '/bedankt' );
                }
                else
                {
                    this.$router.push( '/gegevens/' + ( this.$route.meta.step + 1 ) );
                }
            },

            trySubmit()
            {
                this.$refs.currentForm.onSubmit();
            }
        }
    };
</script>
