<template>
    <svg class="spinner" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" :stroke="stroke" :stroke-width="strokeWidth" :r="r" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(138 50 50)">
            <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 50;360 50 50" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform>
        </circle>
    </svg>
</template>

<style lang="scss" scoped>
    .spinner{
        width:1rem;
    }
</style>

<script>
    export default {
        props: {
            'stroke': {
                type: String,
                default: '#fff'
            },
            'strokeWidth': {
                type: String,
                default: '10'
            },
            'r': {
                type: String,
                default: '35'
            }
        }
    };
</script>
