<template>
    <div class="number-stepper">
        <button @click="minus" :disabled="newValue <= min" type="button">-</button><span>{{ newValue }}</span><button @click="plus" :disabled="newValue >= max" type="button">+</button>
    </div>
</template>

<script>
    export default {
        name: 'NumberStepper',
        props: {
            value: {
                type: Number,
                default: 1
            },
            min: {
                type: Number,
                default: 1
            },
            max: {
                type: Number,
                default: 9
            }
        },
        data()
        {
            return {
                newValue: 1
            };
        },
        created()
        {
            this.newValue = this.value;
        },
        methods: {
            plus()
            {
                this.newValue += 1;
                this.newValue = Math.max( this.newValue, this.min );
                this.$emit( 'input', this.newValue );
                console.log( this.newValue );
            },
            minus()
            {
                this.newValue -= 1;
                this.newValue = Math.min( this.newValue, this.max );
                this.$emit( 'input', this.newValue );
                console.log( this.newValue );
            }
        }
    };

</script>

<style>

</style>
